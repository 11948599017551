import {
  registerApplication,
  RegisterApplicationConfig,
  start,
} from 'single-spa';
import {
  isCurrentRoutePublic,
  isCurrentRouteHybrid,
  isCurrentRouteConsoleHybrid,
  isCurrentRouteSSReportHybrid,
  isSomeRouteActive,
  isActiveRoute,
  isCurrentRouteAFullPage,
  isCurrentRouteSAMLTest,
} from './utils/router.utils';
import {
  Auth0,
  Organizations,
  UserProfile,
  SPARouter,
  GTM,
  FeatureFlags,
  SDK,
  ReleaseFlags,
  Hubspot,
  AccessManagement,
} from '@didomi/utility';
import { initErrorHub } from './error-hub';
// We ensure one instance of ZoneJS is loaded for Angular apps
import 'zone.js';

// Styles
import './styles/styles.css';
import { hasAccessToPath } from './utils/check-path-access';

/**
 * IMPORTANT: the name of this file must match the name in package.json
 * didomi-spa-root-config.ts === "@didomi-spa/root-config"
 */
const getApplicationList = (): RegisterApplicationConfig[] => {
  return [
    {
      name: '@didomi-spa/auth',
      app: () => System.import('@didomi-spa/auth'),
      activeWhen: () =>
        (!Auth0.isAuthenticated() && !isCurrentRouteHybrid()) ||
        isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/topnav',
      app: () => System.import('@didomi-spa/topnav'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        !isCurrentRouteHybrid() &&
        !isCurrentRouteAFullPage() &&
        hasValidatedAccount() &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/sidenav',
      app: () => System.import('@didomi-spa/sidenav'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        !isCurrentRouteHybrid() &&
        !isCurrentRouteAFullPage() &&
        hasValidatedAccount() &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/validate-account',
      app: () => System.import('@didomi-spa/validate-account'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        !hasValidatedAccount() &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/dashboard',
      app: () => System.import('@didomi-spa/dashboard'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        !isSelfServiceOrg() &&
        isActiveRoute('dashboard') &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/history-logs',
      app: () => System.import('@didomi-spa/history-logs'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        !isSelfServiceOrg() &&
        isActiveRoute('versions-and-proofs'),
    },
    {
      name: '@didomi-spa/users-and-roles',
      app: () => System.import('@didomi-spa/users-and-roles'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        (isActiveRoute('organization/users') ||
          isActiveRoute('organization/roles')),
    },
    {
      name: '@didomi-spa/pmp',
      app: () => System.import('@didomi-spa/pmp'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        hasFeature('preference_management_platform') &&
        isActiveRoute('preference-management'),
    },
    {
      name: '@didomi-spa/dsar',
      app: () => System.import('@didomi-spa/dsar'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        !isSelfServiceOrg() &&
        hasValidatedAccount() &&
        hasFeature('dsar') &&
        isActiveRoute('privacy-requests'),
    },
    {
      name: '@didomi-spa/console',
      app: () => System.import('@didomi-spa/console'),
      activeWhen: () =>
        (isCurrentRouteConsoleHybrid() ||
          (hasFeature('consent_management_platform') &&
            Auth0.isAuthenticated() &&
            hasValidatedAccount() &&
            isActiveRoute('privacy-center'))) &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/consent-notices',
      app: () => System.import('@didomi-spa/consent-notices'),
      activeWhen: () =>
        hasFeature('consent_management_platform') &&
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isActiveRoute('consent-notices') &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/smart-vendor-list',
      app: () => System.import('@didomi-spa/smart-vendor-list'),
      activeWhen: () =>
        hasFeature('smart_vendor_list') &&
        hasRelease('smart_vendor_list') &&
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isActiveRoute('smart-vendor-list'),
    },
    {
      name: '@didomi-spa/data-manager',
      app: () => System.import('@didomi-spa/data-manager'),
      activeWhen: () =>
        (hasFeature('consent_management_platform') ||
          hasFeature('preference_management_platform') ||
          hasFeature('compliance_report_v2')) &&
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isActiveRoute('data-manager') &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/cmp-preview',
      app: () => System.import('@didomi-spa/cmp-preview'),
      activeWhen: () =>
        !hasFeature('consent_management_platform') &&
        hasAccessToOrgConsole() &&
        isSomeRouteActive(['data-manager', 'consent-notices']),
    },
    {
      name: '@didomi-spa/pmp-preview',
      app: () => System.import('@didomi-spa/pmp-preview'),
      activeWhen: () =>
        !hasFeature('preference_management_platform') &&
        hasAccessToOrgConsole() &&
        isActiveRoute('preference-management'),
    },
    {
      name: '@didomi-spa/dsar-preview',
      app: () => System.import('@didomi-spa/dsar-preview'),
      activeWhen: () =>
        !hasFeature('dsar') &&
        hasAccessToOrgConsole() &&
        isActiveRoute('privacy-requests'),
    },
    {
      name: '@didomi-spa/organization-setup',
      app: () => System.import('@didomi-spa/organization-setup'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        !hasOrganization() &&
        hasValidatedAccount() &&
        !isActiveRoute('account/settings', true) &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/organization-settings',
      app: () => System.import('@didomi-spa/organization-settings'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isSelfServiceOrg() &&
        isEmailVerified() &&
        isActiveRoute('organization/settings'),
    },
    {
      name: '@didomi-spa/user-settings',
      app: () => System.import('@didomi-spa/user-settings'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isActiveRoute('account/settings', !hasOrganization()),
    },
    {
      name: '@didomi-spa/compliance-report',
      app: () => System.import('@didomi-spa/compliance-report'),
      activeWhen: () =>
        ((Auth0.isAuthenticated() &&
          isSelfServiceOrg() &&
          hasValidatedAccount() &&
          isActiveRoute('dashboard')) ||
          isCurrentRouteSSReportHybrid()) &&
        !isCurrentRouteSAMLTest(),
    },
    {
      name: '@didomi-spa/agnostik-compliance-report',
      app: () => System.import('@didomi-spa/agnostik-compliance-report'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isActiveRoute('agnostik-compliance-report'),
    },
    {
      name: '@didomi-spa/integration-marketplace',
      app: () => System.import('@didomi-spa/integration-marketplace'),
      activeWhen: () =>
        Auth0.isAuthenticated() &&
        hasValidatedAccount() &&
        isActiveRoute('marketplace'),
    },
    {
      name: '@didomi-spa/analytics',
      app: () => System.import('@didomi-spa/analytics'),
      activeWhen: () => hasAccessToOrgConsole() && isActiveRoute('analytics'),
    },
    {
      name: '@didomi-spa/sso',
      app: () => System.import('@didomi-spa/sso'),
      activeWhen: () =>
        Auth0.isAuthenticated() && isActiveRoute('organization/sso'),
    },
    {
      name: '@didomi-spa/standard-text',
      app: () => System.import('@didomi-spa/standard-text'),
      activeWhen: () =>
        hasAccessToOrgConsole() &&
        isActiveRoute('standard-text') &&
        hasCustomFeature('cmp-standard-text'),
    },
    {
      name: '@didomi-spa/product-settings',
      app: () => System.import('@didomi-spa/product-settings'),
      activeWhen: () =>
        hasAccessToOrgConsole() && isActiveRoute('product-settings'),
    },
    {
      name: '@didomi-spa/api-keys',
      app: () => System.import('@didomi-spa/api-keys'),
      activeWhen: () =>
        hasAccessToOrgConsole() && isActiveRoute('organization/keys'),
    },
    {
      name: '@didomi-spa/server-side-preview',
      app: () => System.import('@didomi-spa/server-side-preview'),
      activeWhen: () =>
        hasAccessToOrgConsole() &&
        isActiveRoute('server-side') &&
        !hasCustomFeature('server_side_ui'),
    },
    {
      name: '@didomi-spa/server-side',
      app: () => System.import('@didomi-spa/server-side'),
      activeWhen: () =>
        hasAccessToOrgConsole() &&
        isActiveRoute('server-side') &&
        hasCustomFeature('server_side_ui'),
    },
  ];
};

const initHubspot = async () => {
  try {
    await Hubspot.initHubspot();
  } catch (error) {
    console.error('Error initializing Hubspot: ', error);
  }
};

const hasAccessToOrgConsole = () => {
  return Auth0.isAuthenticated() && hasValidatedAccount() && hasOrganization();
};

function hasCustomFeature(featureName: string): boolean {
  return Organizations.getActiveOrganization()?.custom_features.some(
    (cF) => cF.id === featureName && cF.enabled,
  );
}

function hasFeature(feature: string): boolean {
  return FeatureFlags.isEnabledSync(feature);
}

function hasRelease(feature: string): boolean {
  return ReleaseFlags.isEnabledSync(feature);
}

function hasOrganization(): boolean {
  return !!Organizations.getOrganizationsList()?.length;
}

function isSelfServiceOrg(): boolean {
  return Organizations.getActiveOrganization()?.self_register;
}

function canChatToSupport(): boolean {
  return Organizations.getActiveOrganization()?.allow_chat_support;
}

function isSelfServiceUser(): boolean {
  return UserProfile?.getActiveUserProfile()?.self_register;
}

function hasValidatedAccount(): boolean {
  return (
    (isSelfServiceUser() &&
      isEmailVerified() &&
      hasAcceptedTermsAndPrivacy()) ||
    !isSelfServiceUser()
  );
}

function isEmailVerified(): boolean {
  return (hasOrganization() && !isSelfServiceOrg()) || isUserEmailVerified();
}

function isUserEmailVerified(): boolean {
  return (
    (UserProfile?.getActiveUserProfile()?.self_register &&
      UserProfile?.getActiveUserProfile()?.email_verified) ||
    !UserProfile?.getActiveUserProfile()?.self_register
  );
}

function hasAcceptedTermsAndPrivacy(): boolean {
  return (
    UserProfile?.getActiveUserProfile()?.self_register &&
    !!UserProfile?.getActiveUserProfile()?.terms_version &&
    !!UserProfile?.getActiveUserProfile()?.privacy_policy_version
  );
}

function hideAppLoading(delay = 0) {
  const loadingApp = document.getElementById('loading-app');
  if (loadingApp) {
    setTimeout(() => loadingApp.setAttribute('hidden', ''), delay);
  }
}

function showAppLoading(delay = 0) {
  const loadingApp = document.getElementById('loading-app');
  if (loadingApp) {
    setTimeout(() => loadingApp.removeAttribute('hidden'), delay);
  }
}

function navigateToHomePage() {
  SPARouter.navigateTo('/dashboard');
}

/**
 * Logs out the user if it does not belong to any organization.
 * The public and hybrid routes are expection.
 */
async function loadOrganizations() {
  try {
    return await Organizations.getOrganizations();
  } catch (error) {
    // If there's an error getting the organizations we validate it's that you don't have any organization
    // in which case we continue to logout
    if (
      error === 'There are no organizations, please fetch organizations first'
    ) {
      if (isCurrentRoutePublic() || isCurrentRouteHybrid()) {
        await Auth0.logout();
      }
    }
  }
}

async function bootstrap() {
  // eslint-disable-next-line no-undef
  if (process.env.TARGET_ENV !== 'development') {
    GTM.initGTM();
  }

  await Auth0.initAuth0();

  // This will prevent the user from going back to the main route after redirecting
  if (
    !Auth0.isAuthenticated() &&
    !isCurrentRoutePublic() &&
    !isCurrentRouteHybrid()
  ) {
    Auth0.redirectToUniversalLogin(window.location.origin);
    return;
  }

  SDK.initDidomiSDK();
  initErrorHub();

  if (Auth0.isAuthenticated() && !isCurrentRouteSAMLTest()) {
    await UserProfile.getUserProfile();

    // Only load things for users that have verified their profile
    if (isUserEmailVerified()) {
      // If the user is logged in let's make sure to update the organization data and the URL with the active org
      const organizations = await loadOrganizations();
      if (organizations?.length) {
        const orgId = Organizations.getActiveOrganizationId();
        const path = window.location.pathname;
        const startingPath = path.split('/')[1];
        const firstRoute = path.split('/')[2];
        const isOrgValid = organizations.some((org) => org.id === startingPath);

        // Load Feature and Release flags for the active organization
        await Promise.all([
          FeatureFlags.updateFeatureFlagsList(orgId),
          ReleaseFlags.updateReleaseFlagsList(orgId),
        ]);

        if (startingPath !== orgId) {
          // If the URL path corresponding to the organization doesn't match the active organization
          // If the org in the path is a valid org let's update the active org to reflect that otherwise let's update the URL with a valid org
          if (isOrgValid) {
            Organizations.setActiveOrganizationId(startingPath);
            // Don't redirect to an org if the route is hybrid
            // or if the route is to test the saml connection
          } else if (!isCurrentRouteHybrid()) {
            navigateToHomePage();
          }
        }
        if (startingPath === orgId && !firstRoute) {
          navigateToHomePage();
        }
      } else {
        // If no org, let's go to the organization setup page
        navigateToHomePage();
      }
    }

    // We need the user profile to be loaded before we init Hubspot
    if (hasOrganization() && !isSelfServiceOrg() && canChatToSupport()) {
      initHubspot();
    }
  }

  ReleaseFlags.connectReleaseFlags();
  FeatureFlags.connectFeatureFlags();
  AccessManagement.connectAccessManagement();

  const applications: RegisterApplicationConfig[] = getApplicationList();

  window.addEventListener('single-spa:before-mount-routing-event', (evt) => {
    //@ts-ignore
    const appStatuses = evt.detail.appsByNewStatus;
    const moundedAuth = appStatuses['MOUNTED'].includes('@didomi-spa/auth');
    const mountedMain =
      appStatuses['MOUNTED'].includes('@didomi-spa/topnav') ||
      appStatuses['MOUNTED'].includes('@didomi-spa/sidenav') ||
      appStatuses['MOUNTED'].includes('@didomi-spa/validate-account');

    if (moundedAuth || mountedMain) {
      showAppLoading();
    }
  });

  window.addEventListener('single-spa:app-change', async (evt) => {
    //@ts-ignore
    const appStatuses = evt.detail.appsByNewStatus;
    const mountedAuth = appStatuses['MOUNTED'].includes('@didomi-spa/auth');
    const mountedMain =
      appStatuses['MOUNTED'].includes('@didomi-spa/topnav') ||
      appStatuses['MOUNTED'].includes('@didomi-spa/sidenav') ||
      appStatuses['MOUNTED'].includes('@didomi-spa/validate-account');

    /**
     * We also hide the loader when the console is loaded, because it's the only SPA that gets loaded on the public routes.
     * This means the loader will never disappear unless we check for the console as well.
     */
    const mountedPublicSPAs =
      appStatuses['MOUNTED'].includes('@didomi-spa/console') ||
      appStatuses['MOUNTED'].includes('@didomi-spa/compliance-report');

    /**
     * We also hide the loader when a route is a full page because this hide the topnav and the sidenav
     */
    if (
      mountedAuth ||
      mountedMain ||
      mountedPublicSPAs ||
      isCurrentRouteAFullPage()
    ) {
      hideAppLoading();
    }

    /**
     * Navigates to the home page after the user logs in.
     *
     * HOW IT WORKS:
     * The `single-spa:app-change` event is called whenever a SPA is mounted or unmounted.
     * The event has a list of all the SPAs that are going to be mounted and a list of the SPAs that are going to be unmounted.
     * On change, we check if the list of the unmounted SPAs includes the auth SPA, if this is true,
     * it means that the user has just logged in and we need to navigate to the home page because this is not handled by the auth SPA.
     */
    if (appStatuses['NOT_MOUNTED'].includes('@didomi-spa/auth')) {
      navigateToHomePage();
    }
  });

  applications.forEach(registerApplication);

  // Check if user has access to the page(feature/release/customFeature). If not - redirect to the home page
  const redirectToHome = async () => {
    const canAccessPath = await hasAccessToPath();

    if (!canAccessPath) {
      SPARouter.navigateTo('/dashboard');
      return;
    }

    if (!hasValidatedAccount()) {
      SPARouter.navigateTo('/');
    }

    const isSSOEnabled = await FeatureFlags.isEnabled('sso');
    if (!isSSOEnabled && isActiveRoute('organization/sso')) {
      SPARouter.navigateTo('/dashboard');
    }
  };
  redirectToHome();
  window.addEventListener('orgChanged', redirectToHome);

  /**
   * Setting 'urlRerouteOnly' to true can be better for performance in some situations.
   * If set to true, calls to history.pushState() and history.replaceState()
   * will not trigger a single-spa reroute unless the client side route was changed.
   */
  start({
    /**
     * TODO: See if we can fix that issue on the console router to enable this option again
     *
     * Having this enabled causes the forward/back buttons no sometimes not work inside the console.
     * This is probably related to the Angular router.
     * Somewhat related issue: https://github.com/single-spa/single-spa-angular/issues/94
     */
    // urlRerouteOnly: true,
  });
}

bootstrap();
